<template>
	<div>
		<el-form
			ref="queryForm"
			class="query-box"
			v-model="queryParams"
			label-width="68px"
		>
		<el-row :gutter="20">
			<el-col :span="6">
				<el-form-item
					label="申请编号"
					prop="outOrderNo"
				>
					<el-input
						v-model="queryParams.outOrderNo"
						clearable
						placeholder="请输入申请编号"
						size="small"
						@keyup.enter.native="handleQuery"
					/>
				</el-form-item>
			</el-col>
			<el-col :span="6">
				<el-form-item label="渠道" prop="channelNo">
					<el-select
						v-model="queryParams.channelNo"
						placeholder="渠道"
						clearable
						size="small"
						style="width: 100%"
					>
						<el-option label="全部" value="" />
						<el-option
							v-for="(item, key) of dicts.CHANNEL"
							:key="key"
							:label="item"
							:value="key"
						/>
					</el-select>
				</el-form-item>
			</el-col>
			<el-col :span="6">
				<el-form-item label="活动政策" prop="activityNo">
					<el-select style="width:100%" v-model="queryParams.activityNo" size="small" clearable
						placeholder="请选择活动政策">
	
						<el-option v-for="item in activities" :label="item.activityName" :value="item.activityNo"
							:key="item.activityNo"></el-option>
					</el-select>
				</el-form-item>
			</el-col>
			<el-col :span="6">
				<el-form-item label="代理商编号" prop="agentNo" label-width="82px">
					<el-input
						v-model="queryParams.agentNo"
						clearable
						placeholder="请输入代理商编号"
						size="small"
						@keyup.enter.native="handleQuery"
					/>
				</el-form-item>
			</el-col>
		</el-row>
		<el-row :gutter="20">
			<el-col :span="6">
				<el-form-item
					label="商户编号"
					prop="merchantNo"
				>
					<el-input
						v-model="queryParams.merchantNo"
						clearable
						placeholder="请输入商户编号"
						size="small"
						@keyup.enter.native="handleQuery"
					/>
				</el-form-item>
			</el-col>
			<el-col :span="6">
				<el-form-item
					label="执行结果"
					prop="executeStatus"
				>
					<el-select
						v-model="queryParams.executeStatus"
						clearable
						size="small"
						placeholder="请选择执行结果"
						style="width: 100%"
					>
						<el-option label="成功" value="SUCCESS"></el-option>
						<el-option label="失败" value="FAILED"></el-option>
					</el-select>
				</el-form-item>
			</el-col>
			<el-col :span="6">
				<el-form-item
					label="执行时间"
					name="createDateRange"
				>
					<el-date-picker
						v-model="createDateRange"
						size="small"
						value-format="yyyy-MM-dd"
						type="daterange"
						range-separator="-"
						style="width: 100%"
						start-placeholder="开始时间"
						end-placeholder="结束时间"
					/>
				</el-form-item>
			</el-col>
		</el-row>	
		</el-form>
		<el-row :gutter="10" class="mb8">
			<el-col :span="1.5">
				<el-button
					type="primary"
					icon="el-icon-search"
					size="mini"
					@click="handleQuery"
				>
					查询
				</el-button>
				<el-button
					icon="el-icon-refresh"
					size="mini"
					@click="handleResetQuery"
					>重置</el-button
				>
				<el-button
					icon="el-icon-download"
					size="mini"
					@click="handleExport"
				>
					导出
				</el-button>
			</el-col>
		</el-row>
	</div>
</template>

<script>
import { TerminalApi, MerchantApi } from "@/api";
export default {
	name: "ToolBar",
	props: {
		dicts: {
			type: Object,
			default: {}
		}
	},
	data() {
		return {
			queryParams: {
				outOrderNo: ''
			},
			// queryParams: { provinceCode: "", cityCode: "" },
			createDateRange: [],
			updateDateRange: [],
			showCustomForm: false,
			cityList: "",
			activities: [],
		};
	},
	created() {
		this.queryParams.outOrderNo = this.$route.query.orderNo;
		this.getActivities();
	},
	methods: {
		getActivities() {
            TerminalApi.queryInfo.getActivityInfo().then(response => {
                this.activities = response.data;
            });
        },
		handleQuery() {
			this.commitChange();
		},
		handleResetQuery() {
			this.queryParams = {
				outOrderNo: ''
			};
			this.createDateRange = [];
			this.updateDateRange = [];
			this.commitChange();
		},
		dealWithQueryParams() {
			if(!this.createDateRange){
				this.createDateRange =[]
			}

			let [startCreateTime, endCreateTime] = this.createDateRange;
			this.queryParams.startCreateTime =
				startCreateTime && startCreateTime + " 00:00:00";
			this.queryParams.endCreateTime =
				endCreateTime && endCreateTime + " 23:59:59";

			let [startUpdateTime, endUpdateTime] = this.updateDateRange;
			this.queryParams.startUpdateTime =
				startUpdateTime && startUpdateTime + " 00:00:00";
			this.queryParams.endUpdateTime =
				endUpdateTime && endUpdateTime + " 23:59:59";
		},
		//导出功能
		handleExport() {
			this.$confirm("确定导出商户调价记录吗", "警告", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			}).then(async () => {
				this.dealWithQueryParams();
				let result = await MerchantApi.JobLogExport(
					this.queryParams
				);
				console.log("export----->207", result);
				if (result.success) {
					this.downloadFile(result.data);
				}
			});
		},
		commitChange() {
			this.dealWithQueryParams();
			let params = { ...this.queryParams };
			this.$emit("on-change", params);
		}
	}
};
</script>

<style scoped></style>
